/* @flow */

import React, { PureComponent } from "react";
import { View, Text, Image, Pressable } from "react-native";

//Modules

// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Tabs from "../components/general/tabs.js";
import Background from "../components/general/background.js";
import Loading from "../components/general/loading.js";
import Match from "../components/matches/match.js";
import BottomNavbar from "../components/navigation/bottomnavbar.js";

import H2H from "./match/h2hbios.js";
import Preview from "./match/preview.js";
import Recap from "./match/recap.js";
import Stats from "./match/stats.js";
// import Watch from "./match/watch.js";
import Odds from "./match/odds.js";
import Form from "./player/form.js";

import { getApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

//Helpers
import MainStyles from "../helpers/mainstyles.js";
import GLOBAL from "../helpers/global.js";
import Functions from "../helpers/functions.js";
import useList from "../helpers/useList.js";
import getDomain from "../helpers/getDomain.js";
// import Pressable from 'react-native/Libraries/Components/Pressable/Pressable.js';

// const H2H = React.lazy(() => import("./match/h2hbios.js"));
// const Preview = React.lazy(() => import("./match/preview.js"));
// const Recap = React.lazy(() => import("./match/recap.js"));
// const Stats = React.lazy(() => import("./match/stats.js"));
const Watch = React.lazy(() => import("./match/watch.js"));
// const Odds = React.lazy(() => import("./match/odds.js"));
// const Form = React.lazy(() => import("./player/form.js"));

// // Initialize Firebase
const app = getApp();
const db = getFirestore(app);

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeView: "Live",
      id: this.props?.id || this.props?.route?.params?.id,
      hideNav: this.props?.route?.params?.hideNav,
      watch: this.props.route?.params?.watch,
      mounted: true,
      description:
        "Live scores, stats, point by point recaps, commentary, streams and bios.",
      lastUpdate: 0,
    };
    this.unsubscribeFirestore = null;
  }

  componentDidMount = async () => {
    // const domain = getDomain()
    const displayList = useList();
    this.setState({ displayList });
    setTimeout(() => {
      if (this.state.hideNav && GLOBAL.hideNav) {
        GLOBAL.hideNav();
      }
      this.setup();

      window.addEventListener("focus", this.handleFocus);
      window.addEventListener("blur", this.handleBlur);
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    }, 350);
  };

  componentWillUnmount = async () => {
    this.unmountScreen();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.route?.params?.content !== this.props.route?.params?.content) {
      this.setState({ activeView: this.props.route?.params?.content });
    }
  };

  //
  handleFocus = () => {
    // console.log('match focus')
  };

  handleBlur = () => {
    // console.log('match blur')
    this.unmountScreen();
  };

  unmountScreen = () => {
    this.setState({ mounted: false });
    clearTimeout(this.refresh_data);
    this.refresh_data = null;
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    this.detachFirestore();
  };

  handleVisibilityChange = () => {
    const time_compare = this.state.refresh_time || 1 * 60 * 1000;

    // console.log('Visibility changed to: '+document.visibilityState, "Seconds to compare: "+time_compare/1000+" seconds")

    if (document.visibilityState === "visible") {
      //Trigger an update if the data was updated more than a minute ago
      if (Date.now() - this.state.lastUpdate > time_compare) {
        // console.log('Viewing page and data is stale, asking for refresh')
        this.setup();
      } else {
        // console.log('Viewing page but data is fresh, wait for next update')
      }
    }

    if (document.visibilityState === "visible") {
      this.setupFirestore();
    } else {
      this.detachFirestore();
    }
  };

  setup = async () => {
    clearTimeout(this.refresh_data);

    var id = this.props?.id || this.props?.route?.params?.id;

    var params = {
      id,
      mode: "match",
    };

    var data_r = await Functions.tnnsAPI(params);
    var data = data_r?.data || {};

    var {
      tabs,
      tabs_sections,
      first_tab,
      match_card,
      match_card_daily,
      header,
      abbreviations,
      players,
      notifications,
      favorite,
      isLive,
      refresh_time,
      stream_data,
      id,
      highlights,
      seo,
    } = data;

    this.refresh_time(refresh_time);

    // delete tabs.tab_chat;
    // console.log(tabs)

    //Set the first tab
    if(this.props.route){
      if (this.props.route?.params?.content)
        first_tab = this.props.route?.params?.content.toLowerCase();
    }
    if(this.props.navigation){
      if (first_tab)
        this.props.navigation.setParams({ content: first_tab.toLowerCase() });
    }

    if (this.state.watch && first_tab) {
      first_tab = "watch";
    }

    tabs = Object.values(tabs || {});
    await tabs.sort((a, b) => (a.rank > b.rank ? 1 : -1));

    // tabs_sections = [{id:'chat', title:'Live Chat'}].concat(tabs_sections) //Demo

    //Setup the SEO title
    //Demo string - Live Scores & Updates Roger Federer vs Rafael Nadal - ATP Cup - Date - TNNS Live
    //LIVE UPDATES & STANDINGS Daniil Medvedev - Alex De Minaur - ATP Cup Singles - 4 January 2022 - Eurosport
    var title =
      "Live Scores & Updates " +
      seo.p1 +
      " vs " +
      seo.p2 +
      " - " +
      seo.tournament +
      " " +
      seo.format +
      " - " +
      seo.date +
      " - TNNS Live";
    var description =
      seo.p1 +
      " vs " +
      seo.p2 +
      " live score, streams, prediction, match preview, h2h record, stats on " +
      seo.date +
      " - " +
      seo.tournament;

    var seo_published_time = seo.published_time;
    var seo_modified_time = seo.modified_time;

    if (this.props.navigation) {
      if (this.props.navigation.setOptions) {
        this.props.navigation.setOptions({ title });
      }
    }
    this.setState({ title, description });

    if (!this.state.loading) {
      //Refreshing data
      await this.setState({
        seo_title: title,
        highlights,
        seo_published_time,
        seo_modified_time,
        tabs,
        tabs_sections,
        match_card,
        match_card_daily,
        header,
        id,
        abbreviations,
        players,
        stream_data,
        isLive,
        loading: false,
        lastUpdate: Date.now(),
      });
    } else {
      await this.setState({
        seo_title: title,
        highlights,
        seo_published_time,
        seo_modified_time,
        tabs,
        tabs_sections,
        first_tab,
        activeView: first_tab,
        match_card,
        match_card_daily,
        header,
        id,
        abbreviations,
        players,
        stream_data,
        notifications,
        favorite,
        isLive,
        loading: false,
        lastUpdate: Date.now(),
      });
    }

    if (!this.state.hasSetupFirestore) {
      await this.setState({ hasSetupFirestore: true });
      this.setupFirestore();
    }
  };

  setupFirestore = () => {
    // console.log('Setup firestore')
    //Attach the listener
    this.unsubscribeFirestore = onSnapshot(
      doc(db, "matches_sr", this.state.id),
      (doc) => {
        this.updateFromFirestore(doc);
      }
    );
  };

  updateFromFirestore = async (doc) => {
    // console.log('FS update received', Date.now(), document.visibilityState, `Mounted: ${this.state.mounted}`)
    if (this.state.match_card) {
      // let match_card = this.state.match_card;
      let match_card = { ...this.state.match_card };
      const sport_event_status = doc.data();

      if (sport_event_status.game_state && !sport_event_status.winner_id) {
        //Game Score
        if (sport_event_status.game_state) {
          match_card.game_score = [
            [
              {
                score: sport_event_status.game_state.home_score || "0",
                opacity: 1,
              },
              {
                score: sport_event_status.game_state.away_score || "0",
                opacity: 1,
              },
            ].map((obj) => {
              if (obj.score === 50) obj.score = "AD";
              return obj;
            }),
          ];

          // console.log(sport_event_status)

          //Point type
          match_card.strings.bottom_left =
            {
              match: "Match point",
              set: "Set point",
              break: "Break point",
            }[sport_event_status.game_state.point_type] || "Live";

          //Serving
          if (sport_event_status.game_state.serving === "home") {
            match_card.players[0].serving = true;
            match_card.players[1].serving = false;
          } else if (sport_event_status.game_state.serving === "away") {
            match_card.players[0].serving = false;
            match_card.players[1].serving = true;
          }
        } else {
          match_card.game_score = [];
          match_card.players[0].serving = false;
          match_card.players[1].serving = false;
        }

        //Period Score
        if (sport_event_status.period_scores?.length) {
          sport_event_status.period_scores.forEach((score, i) => {
            if (match_card.scores[i]) {
              match_card.scores[i][0].score = score.home_score;
              match_card.scores[i][1].score = score.away_score;
            }
          });
        }

        await this.setState({ match_card });
      }
    }

    // if(matches['sr:sport_event:58140567']){
    //   const match = matches['sr:sport_event:58140567']
    //   console.log(match.sport_event_status.game_state.home_score, match.sport_event_status.game_state.away_score)
    // }

    // await this.setState({ push: Object.assign(this.state.push, matches) });

    // //Update the list
    // this.filter_matches();
  };

  detachFirestore = () => {
    if (this.unsubscribeFirestore) {
      // console.log('Detaching FS')
      this.unsubscribeFirestore();
    }
  };

  changeTab = (tab) => {
    // console.log('tab', tab)
    // this.setState({ activeView: tab.id });
    if(this.props.navigation){
      this.props.navigation.setParams({ content: tab.id });
    }else{
      // alert('ding')
      this.setState({ activeView: tab.id });
    }
  };

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    this.setState({ refresh_time });
    // console.log('Will refresh in '+refresh_time/1000+' seconds')
    if (refresh_time) {
      this.refresh_data = setTimeout(() => {
        // console.log('Refresh 2')

        var update_data = true;

        if (document.visibilityState === "visible") {
          //Update, window is visible
          update_data = true;
        } else if (this.state.missedUpdates > (1000 * 5 * 60) / refresh_time) {
          //Data is 5 mins old, update
          // console.log('Missed enough updates, update the data')
          update_data = true;
        } else {
          //Window blurred, don't update
          update_data = false;
        }

        // console.log('Refresh requested', 'Visbility: '+document.visibilityState, 'Missed updates: '+this.state.missedUpdates, "Updating: "+update_data)

        if (update_data) {
          //Update
          this.setup();
          this.setState({ missedUpdates: 0 });
        } else {
          //Missed
          //Increase the missed update count and wait for another refresh
          this.setState({ missedUpdates: this.state.missedUpdates + 1 });
          this.refresh_time(refresh_time);
        }
      }, refresh_time);
    }
  };

  renderContent = () => {
    var { overlay } = this.props;
    var { loading } = this.state;

    if (overlay) {
      return loading ? <Loading /> : this.renderMain();
    } else {
      return (
        <>
          <Background
            navigation={this.props.navigation}
            page={"Scores"}
            rightPanel={
              this.state.highlights && {
                render: this.renderRight,
                title: "Match highlights",
                button: {
                  cta: "Watch highlights",
                  onPress: this.openVideo,
                },
              }
            }
          >
            {loading ? <Loading /> : this.renderMain()}
          </Background>
          <BottomNavbar page="Scores" />
        </>
      );
    }
  };

  renderStream = () => {
    var { stream_data } = this.state;
    return (
      <>
        <Watch data={stream_data} player_only width={GLOBAL.column_width} />
      </>
    );
  };

  renderHeader = () => {
    var { header } = this.state;
    return (
      <View
        style={[
          {
            width: "100%",
            paddingVertical: GLOBAL.padding * 2,
            backgroundColor: GLOBAL.style.color,
          },
          MainStyles.flexCenter,
        ]}
      >
        <View
          style={[
            { width: "100%", maxWidth: GLOBAL.maxWidth },
            MainStyles.flexRow,
            MainStyles.flexStartCenter,
          ]}
        >
          <View
            style={[
              { flex: 1, paddingHorizontal: GLOBAL.padding },
              MainStyles.flexCenterStart,
            ]}
          >
            <Text
              style={{
                fontSize: 24,
                color: GLOBAL.style.backgroundColor,
                fontFamily: GLOBAL.fonts.bold,
                marginBottom: GLOBAL.padding / 4,
              }}
            >
              {header?.title}
            </Text>
            <Text
              style={{
                fontSize: 14,
                color: GLOBAL.style.backgroundColor,
                opacity: 0.65,
                fontFamily: GLOBAL.fonts.regular,
              }}
            >
              {header?.subtitle}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  renderMatch = () => {
    // console.log("Render match");
    return (
      <Match
        data={this.state.match_card}
        index={0}
        screen={true}
        hideControls={true}
        disablePress
      />
    );
  };

  renderMain = () => {
    var {
      activeView,
      tabs,
      id,
      isLive,
      abbreviations,
      players,
      stream_data,
      displayList,
    } = this.state;

    let header = null;
    if (displayList) {
      header = this.renderTopSection();
    }

    var render = null;
    if (activeView === "watch") {
      render = <Watch listHeader={header} data={stream_data} />;
    } else if (activeView === "preview") {
      render = (
        <Preview listHeader={header} tabLabel={"Preview"} forceTab={this.forceTab} id={id} />
      );
    } else if (activeView === "stats") {
      render = (
        <Stats listHeader={header}
          tabLabel={"Stats"}
          id={id}
          abbreviations={abbreviations}
          isLive={isLive}
        />
      );
    } else if (activeView === "recap") {
      render = (
        <Recap listHeader={header} tabLabel={"Recap"} id={id} players={players} isLive={isLive} />
      );
    } else if (activeView === "chat") {
      render = null;
    } else if (activeView === "preview") {
      render = (
        <Preview listHeader={header} tabLabel={"Preview"} forceTab={this.forceTab} id={id} />
      );
    } else if (activeView === "odds") {
      render = <Odds listHeader={header} tabLabel={"Odds"} id={id} />;
    } else if (activeView === "h2h&bios") {
      render = <H2H listHeader={header} tabLabel={"H2H & Bios"} id={id} />;
    } else if (activeView === "form") {
      render = (
        <Form listHeader={header}
          tabLabel={"Form"}
          players={players}
          match_id={this.props?.id || this.props?.route?.params?.id}
        />
      );
    }

    if (header) {
      return (
        <View style={[{ width: "100%", flex: 1 }, MainStyles.flexStartCenter]}>
          {render}
        </View>
      );
    }

    return (
      <View style={[{ width: "100%" }, MainStyles.flexStartCenter]}>
        {
          // this.renderHeader()
        }
        <View
          style={{
            maxWidth: GLOBAL.maxWidth,
            width: "100%",
            borderRadius: 0,
            backgroundColor: GLOBAL.isMobile ? null : GLOBAL.style.cardColor,
            borderWidth: 0,
            borderColor: GLOBAL.style.borderColor,
          }}
        >
          <View
            style={[
              { width: "100%" },
              MainStyles.flexRow,
              MainStyles.flexCenterStart,
            ]}
          >
            <View style={{ flex: 1 }}>
              {this.renderTopSection()}
              {render}
            </View>
          </View>
        </View>
      </View>
    );
  };

  renderTopSection = () => {
    var { activeView, tabs, id, isLive, abbreviations, players, stream_data } =
      this.state;
    return (
      <>
        <View
          style={{
            width: "100%",
            borderBottomColor: GLOBAL.style.borderColorSecondary,
            borderBottomWidth: 1,
          }}
        >
          {this.renderMatch()}
        </View>
        <View
          style={[
            {
              width: "100%",
              borderBottomWidth: 1,
              borderBottomColor: GLOBAL.style.borderColorSecondary,
            },
            MainStyles.flexCenter,
          ]}
        >
          <Tabs tabs={tabs} onChange={this.changeTab} active_tab={activeView} />
        </View>
      </>
    );
  };

  openVideo = () => {
    GLOBAL.showVideo(this.state.highlights.url);
  };

  renderRight = () => {
    var { highlights } = this.state;
    if (!highlights) {
      return null;
    }

    const img = highlights.thumbnail;
    const id = highlights.url;
    var id_youtube = id ? id.split("?v=")[1] : "";

    return (
      <Pressable
        onPress={this.openVideo}
        style={{ width: "100%", paddingHorizontal: 0 }}
      >
        <Image
          style={{
            width: "100%",
            aspectRatio: 16 / 9,
            borderRadius: 0,
            marginTop: 0,
            resizeMode: "cover",
          }}
          source={{ uri: img }}
        />
      </Pressable>
    );
  };

  render = () => {
    try {
      return this.renderContent();
    } catch (e) {
      return null;
    }
  };
}
