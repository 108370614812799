function getDomain() {
  let domain = "";
  try {
    // Attempt to read the parent domain
    domain = window.top.location.hostname;
  } catch (error) {
    // If there's a cross-origin error, fall back to the iframe's own domain
    domain = window.location.hostname;
  }
  return domain;
}

export default getDomain;
