import React, { PureComponent } from "react";
import { withNavigation } from "@react-navigation/compat"; // or react-navigation if using v5
import GLOBAL from "../../helpers/global";

class NavigationHelper extends PureComponent {
  componentDidMount = () => {
    GLOBAL.navigation = this.props.navigation;
  };
  render() {
    return null;
  }
}

export default withNavigation(NavigationHelper);
