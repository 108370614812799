/* @flow */

import React, { Component } from 'react';

import {
  View,
  Image,
  Text,
  Pressable,
  ScrollView,
} from 'react-native';

//Modules
import Match from '../../screens/match.js'

//
import GLOBAL from '../../helpers/global.js'
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'

export default class Item extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:this.props.id,
    }
  }

  componentDidUpdate = (prevProps) => {
    // alert('vid')
    // console.log(prevProps.id, this.props.id)
    if(prevProps.id !== this.props.id){
      var id = this.props.id
      // var url = "https://www.youtube.com/embed/uFAPjQy40JQ?autoplay=1"

      // "https://www.youtube.com/watch?v=0ccfcGVP3ko"

      var id_youtube = id ? id.split("?v=")[1] : ''
      var url = "https://www.youtube.com/embed/"+id_youtube+"?autoplay=1"

      this.setState({id:this.props.id, url})
    }
  }

  hide_overlay = () => {
    this.setState({id:null})
    this.props.clearVideo()
  }

  renderOverlay = () => {
    var { id, url } = this.state
    if(id){
      var padding = GLOBAL.padding
      var width = GLOBAL.displayMode === 'mobile' ? '100%' : '75%'
      var size = 36
      return(
        <Pressable onPress={this.hide_overlay} style={[{width:'100%', flex:1, position:'absolute', width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.8)', padding}, MainStyles.flexCenter]}>
          <View style={[{width:width, aspectRatio:1920/1080, backgroundColor:'transparent', borderRadius:0}, MainStyles.flexCenter]}>
            <View style={[{width:'100%', height:"100%"}, MainStyles.flexCenter]}>
              <iframe
              width={'100%'}
              height={'100%'}
              src={url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full-screen"
              allowfullscreen="1"
              autoplay="1"
              >
              </iframe>
            </View>
          </View>
          <Pressable onPress={this.hide_overlay} style={[{backgroundColor:"#4A4E53", height:48, paddingHorizontal:GLOBAL.padding, borderRadius:GLOBAL.borderRadius, marginTop:GLOBAL.padding * 2}, MainStyles.flexCenter]}>
            <Text style={{fontFamily:GLOBAL.fonts.bold, fontSize:14, color:'white'}}>
            CLOSE PLAYER
            </Text>
          </Pressable>
        </Pressable>
      )
    }else{return null}
  }

  render = () => {
    try{
      return this.renderOverlay()
    }catch(e){
      console.error(e)
      return null
    }
  }

}
