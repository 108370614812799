import { Dimensions } from "react-native";

function useList(){
  const location = window?.location;

  const tnnsDomain =
    location?.host &&
    (location.host.includes("localhost") ||
      location.host.includes("tnnslive.com") ||
      location.host.includes("tnnswebtesting"));

  const isMobile = Dimensions.get("window").width < 900;

  const isWidgets = location.pathname.includes('/widgets/')

  return isMobile && tnnsDomain && !isWidgets
};

export default useList
