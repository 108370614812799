/* @flow */

import React, { PureComponent } from "react";
import {
  View,
  Text,
  // Pressable,
  Alert,
  Image,
} from "react-native";

import { Pressable } from "react-native-web-hover";

//Modules

//Components
import MatchButton from "../../components/matches/matchbutton.js";
import ReactCountryFlag from "react-country-flag";

import MatchOverlay from "../../components/general/matchoverlaycard.js";

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";

var daily_margin = 6;
var normal_margin = 12;

var flag_corrections = GLOBAL.flag_corrections || {};

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      theme: this.props?.data?.theme || null,
      time_mode: this.props?.data?.time_mode || null,
      index: this.props.index,
      // backgroundColor:this.props.index%2 ? GLOBAL.style.background_alternating : 'transparent',
      backgroundColor: "transparent",
      spoilerMode:
        GLOBAL.spoilerMode && this.props?.data?.time_mode !== "scheduled",
      theme_list: this.props.theme || null,
    };
  }

  componentDidMount = async () => {
    var favorite = this.props.data?.favorite;
    var notifications = this.props.data?.notifications;
    this.setState({ favorite, notifications });
  };

  componentDidUpdate = async (prevProps) => {
    // console.log('Data update received')
    // console.log('Changed?', prevProps.data !== this.props.data)
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data || null,
        theme: this.props?.data?.theme || null,
        time_mode: this.props?.data?.time_mode || null,
      });
    }
    if (prevProps.index !== this.props.index) {
      this.setState({
        index: this.props.index,
        backgroundColor: "transparent",
      });
    }
  };

  componentWillUnmount = async () => {};

  favorite = (nativeEvent, disableReferred) => {
    var { data } = this.props;

    Functions.toggleFavoriteMatch(data, !this.state.favorite);

    if (!this.state.notifications && !this.state.favorite && !disableReferred) {
      this.notifications(null, true);
    }

    this.setState({ favorite: !this.state.favorite });
  };

  toggleNotifications = async () => {
    var { notifications } = this.state;
    var hidePrompt = await Functions.getAsyncData("prompts/matchNotifications");

    if (!hidePrompt && !notifications) {
      Alert.alert(
        GLOBAL.localization_strings?.alerts?.match_notifications?.title ||
          "Get notifications for this match?",
        GLOBAL.localization_strings?.alerts?.match_notifications?.subtitle ||
          "We'll send you a notification when the match starts, sets finish and the match finishes",
        [
          {
            text:
              GLOBAL.localization_strings?.strings?.[
                "ok + don't show me this again"
              ] || "Ok + don't show me this again",
            onPress: async () => this.changeNotifications(true),
          },
          {
            text: GLOBAL.localization_strings?.strings?.ok || "Ok",
            onPress: () => this.changeNotifications(false),
          },
          {
            text: GLOBAL.localization_strings?.strings?.cancel || "Cancel",
            style: "cancel",
          },
        ]
      );
    } else {
      this.changeNotifications(false);
    }
  };

  changeNotifications = async (disablePrompt) => {
    var { data } = this.props;

    if (disablePrompt) {
      Functions.setAsyncData("prompts/matchNotifications", true);
    }

    Functions.toggleNotificationsMatch(data, !this.state.notifications);
    this.setState({ notifications: !this.state.notifications });
  };

  notifications = (nativeEvent, disableReferred) => {
    if (!this.state.favorite && !disableReferred && !this.state.notifications) {
      this.favorite(null, true);
    }

    this.toggleNotifications();
  };
  //

  openMatch = ({ nativeEvent }) => {
    var { onPressMatch } = this.props;
    var { data } = this.state;

    // alert('hey')

    let id = data.id;
      if (data.id.includes("gs:")) id = `g-${data.id.split(":")[2]}`;
      if (data.id.includes("sr:")) id = data.id.split(":")[2];

    if (onPressMatch) {
      if (this.state.showInfo) {
        this.hideInfo();
      } else {
        this.showInfo();
      }
      // onPressMatch(data, nativeEvent)
    } else if (GLOBAL.externalMode) {
      Functions.openURL("https://tnnslive.com/match/" + id);
    } else {
      // GLOBAL.navigation.push('Match', {id:data.docId})
      GLOBAL.navigation.push("Match", { id });
      // Linking.openURL("https://tnnslive.com/match/" + id);
      // window.open(`${window.location.origin}/match/${id}`, '_self');

    }

    // if(GLOBAL.popUpMatches){
    //   window.open("https://tnnslive.com/match/"+data.docId+"?hideNav=true", "TNNS Match", "width=660,height=960");
    // }else if(GLOBAL.externalMode || !GLOBAL.navigation){
    //   Functions.openURL("https://tnnslive.com/match/"+data.docId)
    // }else{
    //   GLOBAL.navigation.push('Match', {id:data.docId})
    // }
  };

  showInfo = async () => {
    // if(GLOBAL.hideMatch){
    //   await GLOBAL.hideMatch()
    // }
    this.setState({ showInfo: true });
    // GLOBAL.hideMatch = this.hideInfo
  };

  hideInfo = () => {
    this.setState({ showInfo: false });
  };

  openWatch = () => {
    var { data } = this.state;
    if (GLOBAL.externalMode) {
      Functions.openURL(
        "https://tnnslive.com/match/" + data.docId + "?watch=true"
      );
    } else {
      GLOBAL.navigation.push("Match", { id: data.docId, watch: true });
    }
  };

  showMatch = () => {
    this.setState({ spoilerMode: false });
  };

  //Renders
  //NORMAL
  renderNormal = () => {
    var { time_mode } = this.state;
    const { data } = this.props
    var { hideControls } = this.props;
    var players = data?.players || [];

    if (time_mode === "scheduled") {
      return (
        <View
          style={[
            {
              width: "100%",
              paddingVertical: GLOBAL.padding,
              paddingHorizontal: GLOBAL.padding,
            },
            MainStyles.flexRow,
            MainStyles.flexCenter,
          ]}
        >
          <View style={[{ flex: 1 }, MainStyles.flexCenter]}>
            {(data.strings?.top_left || data.strings?.top_right) && (
              <View
                style={[
                  { width: "100%" },
                  MainStyles.flexRow,
                  MainStyles.flexBetweenCenter,
                ]}
              >
                {this.renderNormal_statusString(data.strings?.top_left)}
                {this.renderNormal_statusString(data.strings?.top_right)}
              </View>
            )}
            <View
              style={[
                { width: "100%", marginVertical: GLOBAL.padding / 2 },
                MainStyles.flexRow,
                MainStyles.flexBetweenCenter,
              ]}
            >
              <View
                style={[{ flex: 1, width: "100%" }, MainStyles.flexCenterStart]}
              >
                {players.map((item, index) =>
                  this.renderNormal_player(item, index)
                )}
              </View>
            </View>
            {this.renderNormal_times(data.times || [])}
          </View>
          {data?.controls?.favorite && !hideControls
            ? this.renderNormal_favorite()
            : null}
        </View>
      );
    } else {
      return (
        <View
          style={[
            {
              width: "100%",
              paddingVertical: GLOBAL.padding,
              paddingHorizontal: GLOBAL.padding,
            },
            MainStyles.flexCenter,
          ]}
        >
          {/* {<View
            style={[
              { width: "100%" },
              MainStyles.flexRow,
              MainStyles.flexBetweenCenter,
            ]}
          >
            {this.renderNormal_statusString(data.strings?.top_left)}
            {this.state.spoilerMode
              ? null
              : this.renderNormal_statusString(data.strings?.top_right)}
          </View>} */}
          {(data.strings?.top_left || data.strings?.top_right) && (
              <View
                style={[
                  { width: "100%", marginBottom: GLOBAL.padding/2},
                  MainStyles.flexRow,
                  MainStyles.flexBetweenCenter,
                ]}
              >
                {this.renderNormal_statusString(data.strings?.top_left)}
                {this.renderNormal_statusString(data.strings?.top_right)}
              </View>
            ) || <View style={{marginTop:-4}}/>}
          <View
            style={[
              { width: "100%", marginBottom: GLOBAL.padding / 2 },
              MainStyles.flexRow,
              MainStyles.flexBetweenCenter,
            ]}
          >
            <View style={[{ flex: 1 }, MainStyles.flexCenterStart]}>
              {players.map((item, index) =>
                this.renderNormal_player(item, index)
              )}
            </View>
            <View style={[MainStyles.flexCenter, MainStyles.flexRow]}>
              {this.renderDaily_string(data.string)}
              {data?.game_score
                ? this.renderNormal_scores(data?.game_score || [], true)
                : null}
              {this.state.spoilerMode
                ? this.renderSpoilerReveal()
                : (data.scores || []).length > 0
                ? this.renderNormal_scores(data.scores || [])
                : null}
            </View>
          </View>
          <View
            style={[
              { width: "100%" },
              MainStyles.flexRow,
              MainStyles.flexBetweenCenter,
            ]}
          >
            {this.renderNormal_statusString(data.strings?.bottom_left)}
            {hideControls ? null : this.renderNormal_buttons()}
          </View>
          {this.state.spoilerMode ? null : this.renderNormal_arrow(62)}
        </View>
      );
    }
  };

  renderNormal_buttons = () => {
    var { data } = this.state;
    var buttons = data?.buttons || [];
    if (buttons.length === 0) {
      return null;
    } else {
      return (
        <View
          style={[
            { flex: 1, marginRight: -10, position: "absolute", right: 0 },
            MainStyles.flexRow,
            MainStyles.flexEndCenter,
          ]}
        >
          {buttons.map((item, index) => this.renderNormal_button(item, index))}
        </View>
      );
    }
  };

  renderNormal_button = (item, index) => {
    return (
      <MatchButton
        data={item}
        index={index}
        theme={this.state.theme}
        onPressWatch={this.openWatch}
      />
    );
  };

  renderNormal_times = (times) => {
    return (
      <View
        style={[
          { width: "100%" },
          MainStyles.flexRow,
          MainStyles.flexStartCenter,
        ]}
      >
        {times.map((item, index) => this.renderNormal_time(item, index))}
      </View>
    );
  };

  renderNormal_time = (item, index) => {
    if (item?.title && item?.subtitle) {
      return (
        <View
          style={[
            { marginRight: GLOBAL.padding / 1 },
            MainStyles.flexRow,
            MainStyles.flexStartEnd,
          ]}
        >
          <Text
            style={{
              fontSize: 13,
              color: GLOBAL.style.color_secondary,
              fontFamily: GLOBAL.fonts.regular,
              marginRight: GLOBAL.padding / 4,
            }}
          >
            {item.title}
          </Text>
          <Text
            style={{
              fontSize: 11,
              color: this.props.color || GLOBAL.style.color,
              opacity: 0.5,
              fontFamily: GLOBAL.fonts.regular,
              marginBottom: 0.2,
            }}
          >
            {item.subtitle}
          </Text>
        </View>
      );
    } else {
      return null;
    }
  };

  renderNormal_statusString = (string) => {
    return (
      <Text
        style={{
          fontSize: 13,
          color: this.props.color || GLOBAL.style.color,
          opacity: 0.5,
          fontFamily: GLOBAL.fonts.regular,
        }}
      >
        {string}
      </Text>
    );
  };

  renderNormal_player = (item, index) => {
    var opacity = item.opacity || 1;
    if (this.state.spoilerMode) {
      opacity = 1;
    }
    return (
      <View
        style={[
          { marginVertical: normal_margin / 2, width: "100%" },
          MainStyles.flexRow,
          MainStyles.flexStartCenter,
        ]}
      >
        {!this.state?.data?.gs && this.renderNormal_flags(item?.flags || [])}
        <Text
          style={{
            fontFamily: GLOBAL.fonts.medium,
            color: this.props.color || GLOBAL.style.color,
            fontSize: 16,
            opacity,
            flexShrink: 1,
          }}
          numberOfLines={1}
        >
          {item.name}
        </Text>
        {!item.seed ? null : (
          <Text
            style={{
              fontFamily: GLOBAL.fonts.medium,
              color: this.props.color || GLOBAL.style.color_secondary,
              fontSize: 14,
              opacity,
              marginLeft: GLOBAL.padding / 3,
              marginTop: 1.5,
            }}
          >
            {item.seed}
          </Text>
        )}
        {!item.serving ? null : (
          <View
            style={{
              height: 10,
              width: 10,
              borderRadius: 100,
              backgroundColor: GLOBAL.style.ball,
              marginLeft: GLOBAL.padding / 2,
            }}
          />
        )}
      </View>
    );
  };

  renderNormal_flags = (flags) => {
    var render = null;

    var width = 18;
    var height = 14;

    if (flags.length === 2) {
      width = 10;
      height = 7.14;
      render = (
        <>
          <View style={{ position: "absolute", bottom: -1, left: 0 }}>
            {!flags[0] ? null : (
              <ReactCountryFlag
                countryCode={flag_corrections[flags[0]] || flags[0]}
                svg
                style={this.props.style || { width, height }}
                title={flags[0]}
              />
            )}
          </View>
          <View style={{ position: "absolute", top: -1, right: width / 2 }}>
            {!flags[1] ? null : (
              <ReactCountryFlag
                countryCode={flag_corrections[flags[1]] || flags[1]}
                svg
                style={this.props.style || { width, height }}
                title={flags[1]}
              />
            )}
          </View>
        </>
      );
    } else if (flags.length === 1 && flags[0]) {
      render = (
        <ReactCountryFlag
          countryCode={flag_corrections[flags[0]] || flags[0]}
          svg
          style={this.props.style || { width, height, borderRadius:2 }}
          title={flags[0]}
        />
      );
    }

    return <View style={{ width: 30 }}>{render}</View>;
  };

  renderNormal_string = (string) => {
    if (!string) {
      return null;
    } else {
      return (
        <View
          style={[
            { paddingLeft: GLOBAL.padding },
            MainStyles.flexCenter,
            MainStyles.flexRow,
          ]}
        >
          <Text
            style={{
              fontFamily: GLOBAL.fonts.regular,
              color: this.props.color || GLOBAL.style.color_secondary,
              fontSize: 13,
            }}
          >
            {string}
          </Text>
        </View>
      );
    }
  };

  renderNormal_favorite = (string) => {
    var { favorite, notifications } = this.state;
    // return(
    //   <View style={[{}, MainStyles.flexCenter, MainStyles.flexRow]}>
    //     <View style={{height:96, width:1, backgroundColor:GLOBAL.style.borderColor, marginLeft:GLOBAL.padding/1.5}}/>
    //     <View style={[{marginLeft:8}, MainStyles.flexCenter]}>
    //       <Icon icon='star' size={20} filled={favorite} opacity={favorite ? 1 : 0.5} circled onPress={this.favorite}/>
    //       <Icon icon='notification' size={20} filled={notifications} opacity={notifications ? 1 : 0.5} circled onPress={this.notifications}/>
    //     </View>
    //   </View>
    // )
    return null;
  };

  renderNormal_scores = (scores, game_score) => {
    return (
      <View
        style={[
          {
            borderRightWidth: game_score ? 1 : 0,
            borderRightColor: GLOBAL.style.borderColor,
            paddingRight: game_score ? GLOBAL.padding / 1.5 : 0,
            marginRight: game_score ? 0 : -8,
          },
          MainStyles.flexRow,
          MainStyles.flexEndCenter,
        ]}
      >
        {scores.map((item, index) =>
          this.renderNormal_score(item, index, game_score)
        )}
      </View>
    );
  };

  renderNormal_score = (score, index, game_score) => {
    var textStyle = {
      fontFamily: GLOBAL.fonts.bold,
      fontSize: 14,
      color: this.props.color || GLOBAL.style.color,
    };

    var opacity_reduced = 0.5;
    var opacity_1 = score[0]?.score >= score[1]?.score ? 1 : opacity_reduced;
    var opacity_2 = score[0]?.score <= score[1]?.score ? 1 : opacity_reduced;

    return (
      <View key={index} style={[{ width: 28 }, MainStyles.flexCenter]}>
        {this.render_scoreItem(score[0], opacity_1, 16, game_score)}
        <View style={{ height: normal_margin }} />
        {this.render_scoreItem(score[1], opacity_2, 16, game_score)}
      </View>
    );
  };

  render_scoreItem = (data, opacity, fontSize, game_score, fontFamily) => {
    if (!fontFamily) {
      fontFamily = GLOBAL.fonts.medium;
    }

    var textStyle = {
      fontFamily,
      fontSize: fontSize,
      color: this.props.color || GLOBAL.style.color,
    };
    return (
      <View style={[MainStyles.flexCenter, {}]}>
        <Text style={[textStyle, { opacity }]}>{data?.score}</Text>
        {!data?.tiebreak ? null : (
          <Text
            style={[
              {
                fontFamily,
                fontSize: fontSize - 4,
                color: this.props.color || GLOBAL.style.color,
                position: "absolute",
                right: -fontSize / 2,
                top: -fontSize / 7,
              },
              { opacity },
            ]}
          >
            {data?.tiebreak}
          </Text>
        )}
      </View>
    );
  };

  renderNormal_arrow = (height) => {
    var { data } = this.state;
    return (
      <View
        style={[
          MainStyles.flexColumn,
          MainStyles.flexCenter,
          { position: "absolute", right: 0, height },
        ]}
      >
        {this.renderArrow(data?.players[0]?.showArrow, height / 2)}
        {this.renderArrow(data?.players[1]?.showArrow, height / 2)}
      </View>
    );
  };

  renderArrow = (active, height) => {
    if (active && this.state.mode !== "scheduled") {
      return (
        <View style={[{ height }, MainStyles.flexCenter]}>
          <Image
            style={{
              width: 6,
              height: 12,
              resizeMode: "contain",
              tintColor: GLOBAL.style.color_score_arrow,
            }}
            source={require("../../assets/icons/scorearrow.png")}
          />
        </View>
      );
    } else {
      return <View style={{ width: 6, height }} />;
    }
  };

  //DAILY
  renderDaily = () => {
    var { data, time_mode } = this.state;
    var players = data?.players || [];
    var buttons = data?.buttons || [];
    var hasButtons = buttons.length > 0;

    var maxWidth = 4000;

    return (
      <View
        style={[
          {
            width: "100%",
            paddingVertical: data?.stream ? 12 : 8,
            paddingHorizontal: GLOBAL.padding,
          },
          MainStyles.flexRow,
          MainStyles.flexBetweenCenter,
        ]}
      >
        <View style={[{ flex: 1 }, MainStyles.flexCenterStart]}>
          {!data.stream
            ? null
            : this.renderDaily_string_stream(data.stream?.title)}
          <View
            style={[
              { width: "100%", maxWidth },
              MainStyles.flexRow,
              MainStyles.flexBetweenCenter,
            ]}
          >
            <View style={[{ flex: 1 }, MainStyles.flexCenterStart]}>
              {players.map((item, index) =>
                this.renderDaily_player(item, index, players)
              )}
            </View>
            <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
              {this.renderDaily_string(data.string, data.subtitle)}
              {data?.game_score
                ? this.renderDaily_scores(data?.game_score || [], true)
                : null}
              {this.state.spoilerMode
                ? this.renderSpoilerReveal()
                : (data.scores || []).length > 0
                ? this.renderDaily_scores(data.scores || [])
                : null}
              {data.controls?.favorite ? this.renderDaily_favorite() : null}
              {this.state.spoilerMode || hasButtons
                ? null
                : this.renderDaily_arrow(40)}
              {data.stream || !hasButtons
                ? null
                : this.renderDaily_buttons(buttons)}
            </View>
          </View>
        </View>
        {data.stream ? this.renderDaily_stream_buttons() : null}
      </View>
    );
  };

  renderDaily_player = (item, index, players) => {
    var opacity = item.opacity || 1;
    if (this.state.spoilerMode) {
      opacity = 1;
    }

    var other_player = players[index ? 0 : 1];

    var fontFamily = GLOBAL.fonts.regular;
    if (this.props.theme === "ltr") {
      opacity = 1;
      if (other_player.opacity < 1) {
        fontFamily = GLOBAL.fonts.bold;
      }
    }

    return (
      <View
        style={[
          { flex: 1, marginVertical: daily_margin / 2 },
          MainStyles.flexRow,
          MainStyles.flexStartCenter,
        ]}
      >
        {this.renderDaily_flags(item?.flags || [])}
        <Text
          style={{
            fontFamily,
            color: this.props.color || GLOBAL.style.color,
            fontSize: 13,
            opacity,
            flexShrink: 1,
          }}
          numberOfLines={1}
        >
          {item.name}
        </Text>
        {!item.seed ? null : (
          <Text
            style={{
              fontFamily,
              color: this.props.color || GLOBAL.style.color_secondary,
              opacity,
              fontSize: 10,
              marginLeft: GLOBAL.padding / 4,
              marginTop: 2,
            }}
          >
            {item.seed}
          </Text>
        )}
        {!item.serving ? null : (
          <View
            style={{
              height: 8,
              width: 8,
              borderRadius: 100,
              backgroundColor: GLOBAL.style.ball,
              marginLeft: GLOBAL.padding / 2,
            }}
          />
        )}
      </View>
    );
  };

  renderDaily_flags = (flags) => {
    var render = null;

    var width = 14;
    var height = 10;

    if (flags.length === 2) {
      width = 10;
      height = 7.14;
      render = (
        <>
          <View style={{ position: "absolute", bottom: -1, left: 0 }}>
            {!flags[0] ? null : (
              <ReactCountryFlag
                countryCode={flag_corrections[flags[0]] || flags[0]}
                svg
                style={this.props.style || { width, height }}
                title={flags[0]}
              />
            )}
          </View>
          <View style={{ position: "absolute", top: -1, right: width / 2 }}>
            {!flags[1] ? null : (
              <ReactCountryFlag
                countryCode={flag_corrections[flags[1]] || flags[1]}
                svg
                style={this.props.style || { width, height }}
                title={flags[1]}
              />
            )}
          </View>
        </>
      );
    } else if (flags.length === 1 && flags[0]) {
      render = (
        <ReactCountryFlag
          countryCode={flag_corrections[flags[0]] || flags[0]}
          svg
          style={this.props.style || { width, height }}
          title={flags[0]}
        />
      );
    }

    return <View style={{ width: 22 }}>{render}</View>;
  };

  renderDaily_string = (string, subtitle) => {
    if (!string) {
      return null;
    } else {
      return (
        <View
          style={[{ paddingLeft: GLOBAL.padding }, MainStyles.flexCenterEnd]}
        >
          <Text
            style={{
              fontFamily: GLOBAL.fonts.regular,
              color:
                this.props.color || subtitle
                  ? GLOBAL.style.color
                  : GLOBAL.style.color_secondary,
              fontSize: 13,
            }}
          >
            {string}
          </Text>
          {!subtitle ? null : (
            <Text
              style={{
                fontFamily: GLOBAL.fonts.regular,
                color: this.props.color || GLOBAL.style.color_secondary,
                fontSize: 10,
                marginTop: 2,
                flexWrap: "wrap",
                textAlign: "right",
              }}
            >
              {subtitle}
            </Text>
          )}
        </View>
      );
    }
  };

  renderDaily_string_stream = (string) => {
    if (!string) {
      return null;
    } else {
      return (
        <View style={[{}, MainStyles.flexCenterEnd]}>
          <Text
            style={{
              fontFamily: GLOBAL.fonts.regular,
              color: GLOBAL.style.color_secondary,
              fontSize: 13,
              marginBottom: GLOBAL.padding / 2,
            }}
            numberOfLines={1}
          >
            {string}
          </Text>
        </View>
      );
    }
  };

  renderDaily_stream_buttons = () => {
    var { data } = this.state;
    var logo = data.stream?.logo;
    var size = 40;
    var maxSize = 50;
    var ratio = 1;
    return (
      <View style={[{}, MainStyles.flexRow, MainStyles.flexCenter]}>
        <View
          style={[
            MainStyles.flexCenter,
            {
              marginLeft: GLOBAL.padding / ratio,
              paddingLeft: GLOBAL.padding / ratio,
              borderLeftColor: GLOBAL.style.borderColor,
              borderLeftWidth: 1,
              minHeight: 2,
            },
            MainStyles.flexBetweenCenter,
          ]}
        >
          {!logo ? null : (
            <Image
              style={[
                {
                  height: size,
                  width: size,
                  maxWidth: maxSize,
                  marginBottom: GLOBAL.padding / 2,
                  resizeMode: "contain",
                },
              ]}
              source={{ uri: logo.url }}
            />
          )}
          <View style={[{ width: 72 }, MainStyles.flexCenter]}>
            {data.stream?.string ? (
              <Text
                style={{
                  fontFamily: GLOBAL.fonts.regular,
                  color: GLOBAL.style.color_secondary,
                  fontSize: 13,
                  marginBottom: -2,
                }}
              >
                {data.stream?.string}
              </Text>
            ) : (
              <MatchButton
                data={data.stream}
                index={0}
                theme={this.state.theme}
                mode={"normal"}
                string={"Watch"}
                onPressWatch={this.openWatch}
              />
            )}
          </View>
        </View>
      </View>
    );
  };

  renderDaily_favorite = (string) => {
    var { favorite } = this.state;
    // return(
    //   <View style={[{}, MainStyles.flexCenter, MainStyles.flexRow]}>
    //     <View style={{height:40, width:1, backgroundColor:GLOBAL.style.borderColor, marginLeft:GLOBAL.padding/1.5}}/>
    //     <Pressable style={[{width:28 + GLOBAL.padding/2, marginRight:-GLOBAL.padding/2, height:40}, MainStyles.flexCenter]} onPress={this.favorite}>
    //       <Icon icon='star' size={20} filled={favorite} opacity={favorite ? 1 : 0.5}/>
    //     </Pressable>
    //   </View>
    // )
    return null;
  };

  renderDaily_scores = (scores, game_score) => {
    return (
      <View
        style={[
          {
            borderRightWidth: game_score ? 1 : 0,
            borderRightColor: GLOBAL.style.borderColor,
            paddingRight: game_score ? GLOBAL.padding / 2 : 0,
            marginRight: game_score ? 0 : -4,
          },
          MainStyles.flexRow,
          MainStyles.flexEndCenter,
        ]}
      >
        {scores.map((item, index) =>
          this.renderDaily_score(item, index, game_score)
        )}
      </View>
    );
  };

  renderDaily_score = (score, index, game_score) => {
    var textStyle = {
      fontFamily: GLOBAL.fonts.bold,
      fontSize: 13,
      color: this.props.color || GLOBAL.style.color,
    };

    var opacity_reduced = 0.5;
    var opacity_1 = score[0]?.score >= score[1]?.score ? 1 : opacity_reduced;
    var opacity_2 = score[0]?.score <= score[1]?.score ? 1 : opacity_reduced;

    var fontFamily_1 = GLOBAL.fonts.medium;
    var fontFamily_2 = GLOBAL.fonts.medium;
    if (this.props.theme === "ltr") {
      if (opacity_1 < 1) {
        fontFamily_2 = GLOBAL.fonts.bold;
      }
      if (opacity_2 < 1) {
        fontFamily_1 = GLOBAL.fonts.bold;
      }

      opacity_1 = 1;
      opacity_2 = 1;
    }

    return (
      <View key={index} style={[{ width: 21 }, MainStyles.flexCenter]}>
        {this.render_scoreItem(
          score[0],
          opacity_1,
          13,
          game_score,
          fontFamily_1
        )}
        <View style={{ height: 6 }} />
        {this.render_scoreItem(
          score[1],
          opacity_2,
          13,
          game_score,
          fontFamily_2
        )}
      </View>
    );
  };

  renderDaily_arrow = (height) => {
    var { data } = this.state;
    return (
      <View
        style={[
          MainStyles.flexColumn,
          MainStyles.flexCenter,
          { position: "absolute", right: -16, height, opacity: 0.33 },
        ]}
      >
        {this.renderArrow(data?.players[0]?.showArrow, height / 2)}
        {this.renderArrow(data?.players[1]?.showArrow, height / 2)}
      </View>
    );
  };

  renderDaily_buttons = (buttons) => {
    var { data } = this.state;

    if (buttons?.length > 0 && !this.props.hideVideos) {
      return (
        <View
          style={[
            { marginRight: -GLOBAL.padding / 2 },
            MainStyles.flexRow,
            MainStyles.flexEndCenter,
          ]}
        >
          <View
            style={{
              height: 36,
              width: 1,
              backgroundColor: GLOBAL.style.borderColor,
              marginHorizontal: GLOBAL.padding / 2,
            }}
          />
          {buttons.map((item, index) => this.renderDaily_button(item, index))}
        </View>
      );
    } else {
      return null;
    }
  };

  renderDaily_button = (item, index) => {
    return (
      <MatchButton
        data={item}
        index={index}
        theme={this.state.theme}
        mode={"daily"}
        onPressWatch={this.openWatch}
      />
    );
  };

  //Legacy
  renderLegacy = () => {
    var { data } = this.state;
    return (
      <View
        style={[
          {
            width: "100%",
            paddingVertical: GLOBAL.padding / 2,
            paddingHorizontal: GLOBAL.padding,
          },
          MainStyles.flexCenterStart,
        ]}
      >
        <Text
          style={{
            fontSize: 13,
            color: this.props.color || GLOBAL.style.color,
            fontFamily: GLOBAL.fonts.medium,
            textAlign: "left",
          }}
        >
          {data.title}
        </Text>
        <Text
          style={{
            fontSize: 13,
            color: this.props.color || GLOBAL.style.color_secondary,
            fontFamily: GLOBAL.fonts.regular,
            textAlign: "left",
            marginTop: 2,
          }}
        >
          {data.subtitle}
        </Text>
      </View>
    );
  };

  //Spoiler
  renderSpoilerReveal = () => {
    return (
      <Pressable
        style={[{}, MainStyles.flexCenterEnd]}
        onPress={this.showMatch}
      >
        <View
          style={{
            paddingVertical: 8,
            paddingHorizontal: 8,
            borderRadius: 4,
            backgroundColor: GLOBAL.style.borderColor,
          }}
        >
          <Text
            style={{
              fontSize: 11,
              color: GLOBAL.style.header_color,
              fontFamily: GLOBAL.fonts.black,
            }}
          >
            Show Result
          </Text>
        </View>
      </Pressable>
    );
  };

  renderInfo = () => {
    var { showInfo } = this.state;
    var backgroundColor = "#D1D2D3";
    // var backgroundColor = 'white'
    return !showInfo ? null : (
      <View
        style={[
          { width: "100%", backgroundColor },
          MainStyles.flexCenter,
          MainStyles.flexRow,
        ]}
      >
        <View style={{ width: 16, height: "100%", backgroundColor }} />
        <View style={{ flex: 1 }}>
          <MatchOverlay id={this.state.data?.docId} showBranding />
        </View>
      </View>
    );
  };

  render = () => {
    var { theme, index, backgroundColor } = this.state;
    var { disablePress } = this.props;

    var render = null;
    if (theme === "daily") {
      render = this.renderDaily();
    } else if (theme === "legacy") {
      render = this.renderLegacy();
    } else if (theme === "normal") {
      render = this.renderNormal();
    }

    // var maxWidth = 420

    try {
      return (
        <>
          <Pressable
            style={({ hovered, focused, pressed }) => [
              {
                width: "100%",
                backgroundColor,
                borderTopColor: GLOBAL.style.borderColorSecondary,
                borderTopWidth: index > 0 ? 1 : 0,
              },
              hovered &&
                !disablePress && { backgroundColor: GLOBAL.style.hover },
              // focused && styles.buttonFocused,
              // pressed && styles.buttonPressed
            ]}
            // style={{width:'100%', backgroundColor, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0}}
            onPress={disablePress ? null : this.props.onPress || this.openMatch}
          >
            {render}
          </Pressable>
          {this.renderInfo()}
        </>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };
}
