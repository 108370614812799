/* @flow */

import React, { PureComponent, useMemo, useState } from "react";
import { View, Text, Pressable } from "react-native";

//Modules

//Components
import Background from "../components/general/background.js";
import BottomNavbar from "../components/navigation/bottomnavbar.js";
import Tournaments from "./matches/tournaments.js";
import Header from "../components/seo/header.js";

//Helpers
import useList from "../helpers/useList.js";
import MainStyles from "../helpers/mainstyles.js";
import GLOBAL from "../helpers/global.js";

const TournamentsList = () => {
  const [state, setState] = useState({
    loading: true,
    activeView: "scores",
    title:
      "Live Tennis Scores - Tennis Scores, Streams, Results, ATP, WTA & ITF Rankings",
    description:
      "Tennis live scores on TNNS are the fastest & most comprehensive tennis scores - with live real time scores and results from all pro tennis tournaments. ATP and WTA tennis live, ATP & WTA Rankings!",
  });

  const displayList = useList()

  const renderContent = () => {
    return (
      <>
        <Header title={state.title} description={state.description} />
        <Background page="Tournaments" hideColumn>
          {renderMain()}
        </Background>
        <BottomNavbar />
      </>
    );
  };

  const renderMain = () => {
    var render = <Tournaments />;

    if (displayList) {
      return(
        <View style={[{ width: "100%", flex:1, backgroundColor: GLOBAL.style.cardColor }, MainStyles.flexStartCenter]}>
          {render}
        </View>
      )
    }

    return (
      <View style={[{ width: "100%" }, MainStyles.flexStartCenter]}>
        <View
          style={{
            width: "100%",
            height: GLOBAL.displayMode === "web" ? 0 : 0,
            backgroundColor: GLOBAL.style.color,
          }}
        />
        <View
          style={[
            { width: "100%", maxWidth: GLOBAL.maxWidth },
            MainStyles.flexStartStart,
          ]}
        >
          <View
            style={[
              { width: "100%", backgroundColor: GLOBAL.style.cardColor },
              MainStyles.flexRow,
              MainStyles.flexCenterStart,
            ]}
          >
            {render}
          </View>
        </View>
      </View>
    );
  };

  try {
    return renderContent();
  } catch (e) {
    return null;
  }

  return null;
};

export default React.memo(TournamentsList);
