// var buttonSize = 42

var fontColor = '#001021'
var buttonSize = 42

var colors = {
  alternatingBackground: 'rgba(0,0,20,0.03)',
  button: '#5723E7',
  button_hover:'#400FC8',
  yellow: '#FFEA00',
  teal: '#3CD782',
  pink: '#EC1181',
  dark:"#1d2228",
  grey: '#F4F5F8',
  darkBlue: '#006BFF',
  lightBlue: '#4ACDFF',
  font: fontColor,
  fontLight: 'rgba(0,16,33,0.33)',
  lightGrey: 'rgba(0,16,33,0.12)',
  lightLightGrey: 'rgba(0,16,33,0.06)',
  border: 'rgba(0,16,33,0.04)',
  // pink: '#FD297B',
  orange: '#FF5864',
  // button: '#00D2FF',
  // purple: '#4A00E0',
  purple: '#9E53FF',
  backgroundGrey: '#EBEFF6',
  snapchat: '#FFFC00',
  youtube: "#e52d27",
  tiktok: "#1d1d1d",
  transparentBlack: 'rgba(0,0,0,0.20)',
  backgroundDark: '#06070D',
  green: "#29F599",
  volt: "#C5FF00",
  utr: "#29BAB6",
 }

 var style_light = {
  // accent:"#DC1358",
  hover:'rgb(248,248,248)',
  hoverTab:'rgb(222,222,224)',
  accent:"#30DCA1", //green
  // accent:"#DE0062", //red
  cardColor:"white",
  screenBackgroundColor:'#FAFBFB',
  backgroundColor:'rgb(250,250,250)',
  featured:'#151213',
  secondaryColor:'#F5F5FA',
  borderColor:'rgba(232,232,232,1)',
  borderColorSecondary:'rgb(245,245,245)',
  background_alternating:'rgba(0,0,0,0.03)',
  headerColor:'white',
  header_backgroundColor:'white',
  header_color:'black',
  header:{
    color:'#08080A',
    backgroundColor:'#FFF',
  },
  section_title:{
    // color:'#08080A',
    // background:'#F5F5FA',
    color:'white',
    background:'#121212',
  },
  section_subtitle:{
    color:'black',
    background:'white',
  },
  color:'#08080A',
  color_secondary:'rgb(150,150,150)',
  ball:'#A0FA0A',
  streaming:'#FA0A6C',
  blurType:'dark',
  isDark:false,
  button:{
    background:'#1A1A1A',
    color:'white'
  },
  navbar:{
    background:'white',
  },
  media:{
    background:'white',
  }
}

var style_dark = {
  cardColor:'#1D1D1F',
  accent:"#30DCA1",
  gradient:['#121212', '#0C0C0C'],
  alternatingBackground:'rgba(255,255,255,0.04)',
  backgroundColor:'#151213',
  featured:'rgba(255,255,255,0.04)',
  bg_opacity:0.14,
  color:'white',
  color_secondary:'rgba(255,255,255,0.67)',
  section_title:{
    color:'white',
    background:'#2F096B',
  },
  section_subtitle:{
    color:'black',
    background:'white',
  },
  ball:'#A0FA0A',
  color_font:'white',
  color_searchbar:'#E8E8EB',
  color_card:"#1D1D1F",
  color_card_side:"#181819",
  borderColor:'rgba(255,255,255,0.04)',
  color_accent_1:'#20057E',
  // color_accent_1:colors.purple,
  color_accent_2:colors.volt,
  color_font_accent:'white',
  color_tab_main:fontColor,
  color_tab_font_main:'black',
  color_tab_secondary:'rgba(255,255,255,0.1)',
  color_tab_font_secondary:fontColor,
}

var GLOBAL = {
  logos:{
    atp:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/logos%2Fatplogotnns.webp?alt=media&token=64e022c3-291d-4d72-9c5c-9ab576bb2309",
    wta:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/logos%2Fwtalogotnns.webp?alt=media&token=8f4c5bfe-47bf-4f83-bdef-123996b63c2e",
    challenger:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/logos%2Fatpchallenger-text.webp?alt=media&token=73174ae5-eea9-4fd3-9985-9182d4eaa9a2",
    itf:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/misc%2Fitflogo.webp?alt=media&token=9491fc34-92b7-432d-a4af-649607135377",
  },
  column_width:340,
  button_height:44,
  page:'Home',
  padding:16,
  matchCardStyle_completed:'visual',
  matchCardStyle_scheduled:'visual',
  matchCardStyle_live:'visual',
  tournamentsObj:{},
  maxWidth:820,
  spacing:20,
  spacing_card:30,
  borderRadius:4,
  logo:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/web%2Flogo-light.png?alt=media&token=0bb755a9-6535-44d4-acb4-afe4229eb1c9",
  style_light:{
    gradient:['white', 'rgba(245,245,255,1)'],
    alternatingBackground:colors.alternatingBackground,
    backgroundColor:'white',
    bg_opacity:0.32,
    color_font:'#121212',
    color_searchbar:'#E8E8EB',
    color_card:"#E5E6EE",
    color_card_side:"#F2F2F7",
    color_border:'rgba(0,0,0,0.04)',
    color_accent_1:'#20057E',
    color_accent_2:colors.volt,
    color_font_accent:'white',
    color_tab_main:fontColor,
    color_tab_font_main:'black',
    color_tab_secondary:'rgba(255,255,255,0.1)',
    color_tab_font_secondary:fontColor,
  },
  colors,
  darkMode:true,
  // style:style_light,
  style:style_dark,
  style_light,
  style_dark,
  // fonts:{
  //   ultra:'ClanOT-Ultra',
  //   black:'ClanOT-Black',
  //   bold:'ClanOT-Bold',
  //   medium:'ClanOT-Medium',
  //   regular:'ClanOT-News',
  //   // thin:'ClanOT-Thin',
  // },
  fonts:{
    ultra:'Inter-Black',
    black:'Inter-Black',
    bold:'Inter-Bold',
    medium:'Inter-Semibold',
    regular:'Inter-Regular',
    // thin:'ClanOT-Thin',
  },
  flag_corrections:{
    'GBR':'GB'
  },
  // fonts:{
  //  regular:'Inter-Regular',
  //  bold:'Inter-Bold',
  //  semibold:'Inter-Semibold',
  //  medium:'Inter-Semibold',
  //  black:'Inter-Black',
  // },
  styles:{
     tabView:{borderRadius:800, paddingHorizontal:16, paddingVertical:8, marginRight:0},
     tabViewActive:{backgroundColor:'white'},
     tabText:{fontFamily:'Roboto-Regular', color:'rgba(255,255,255,0.67)', fontSize:16, letterSpacing:-0.3},
     tabTextActive:{color:fontColor, fontFamily:'Roboto-Bold'}
   },
   shadows:{
       text:{
        textShadowOffset: { width: 0, height: 1 },
        textShadowRadius: 4,
        textShadowColor: 'rgba(0,16,33,0.20)',
      },
      view:{
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.06,
        shadowRadius: 3.84,
      },
     },
};

export default GLOBAL
