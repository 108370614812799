/* @flow */
import React, { useEffect, useState, Suspense, memo } from "react";
import { View, Dimensions, StyleSheet, ActivityIndicator } from "react-native";

// import "react-native-gesture-handler";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import GLOBAL from "./helpers/global.js";
// import NavHelper from "./components/general/NavHelper.js";
import HomeWeb from "./screens/home_web.js";
// import Search from "./screens/search.js";
// import News from "./screens/news.js";
// import Videos from "./screens/videos.js";
// import Podcasts from "./screens/podcasts.js";
// import Widget from "./screens/widgets.js";
// import WidgetTest from "./screens/widget_test.js";
// import Download from "./screens/download.js";
// import Article from "./screens/article.js";
// import Stream from "./screens/stream.js";
// import Streams from "./screens/streams.js";
// import HowToStream from "./screens/howtostream.js";
// import Support from "./screens/support.js";
// import Feedback from "./screens/feedback.js";
// import Widgets from "./screens/widget.js";
// import WidgetBuilder from "./screens/widgetbuilder.js";
// import FantasyRules from "./screens/fantasyrules.js";
// import FantasyBracket from "./screens/fantasybracket.js";
import Match from "./screens/match.js";
// import MatchNew from "./screens/MatchNew.js";
// import H2H from "./screens/h2h.js";
import Tournament from "./screens/tournament.js";
import Tournaments from "./screens/tournaments.js";
// import Player from "./screens/player.js";
// import Rankings from "./screens/rankings.js";
// import Ranking from "./screens/ranking.js";
import VideoOverlay from "./components/general/videooverlay.js";
// import Demos from './screens/Demos.js'

// import BottomNavBar from "./components/navigation/bottomnavbarfixed.js";

// const HomeWeb = React.lazy(() => import("./screens/home_web.js"));
const Search = React.lazy(() => import("./screens/search.js"));
const News = React.lazy(() => import("./screens/news.js"));
const Videos = React.lazy(() => import("./screens/videos.js"));
const Podcasts = React.lazy(() => import("./screens/podcasts.js"));
const Widget = React.lazy(() => import("./screens/widgets.js"));
const WidgetTest = React.lazy(() => import("./screens/widget_test.js"));
const Demos = React.lazy(() => import("./screens/Demos"));

const Download = React.lazy(() => import("./screens/download.js"));
const Article = React.lazy(() => import("./screens/article.js"));
const Stream = React.lazy(() => import("./screens/stream.js"));
const Streams = React.lazy(() => import("./screens/streams.js"));
const HowToStream = React.lazy(() => import("./screens/howtostream.js"));
const Support = React.lazy(() => import("./screens/support.js"));
const Feedback = React.lazy(() => import("./screens/feedback.js"));
const Widgets = React.lazy(() => import("./screens/widget.js"));
const WidgetBuilder = React.lazy(() => import("./screens/widgetbuilder.js"));
const FantasyRules = React.lazy(() => import("./screens/fantasyrules.js"));
const FantasyBracket = React.lazy(() => import("./screens/fantasybracket.js"));
// const Match = React.lazy(() => import("./screens/match.js"));
// const MatchNew = React.lazy(() => import("./screens/MatchNew.js"));
const H2H = React.lazy(() => import("./screens/h2h.js"));
// const Tournament = React.lazy(() => import("./screens/tournament.js"));
// const Tournaments = React.lazy(() => import("./screens/tournaments.js"));
const Player = React.lazy(() => import("./screens/player.js"));
const Rankings = React.lazy(() => import("./screens/rankings.js"));
const Ranking = React.lazy(() => import("./screens/ranking.js"));
// const VideoOverlay = React.lazy(() => import("./components/general/videooverlay.js"));

const Stack = createStackNavigator();

const linking = {
  prefixes: [
    "https://downloadtennis.com",
    "https://www.downloadtennis.com",
    "https://tnnsweb.web.app/",
    "tennisrn://",
    "http://localhost:3001",
    "https://tnnslive.com",
    "https://www.tnnslive.com",
  ],
  config: {
    screens: {
      // Auth:'',
      Home: "",
      Scores: "scores",
      // Matches:'matches',
      Match: "match/:id",
      MatchNew: "matchnew/:tournament/:season/:round/:players/:id/:mode",
      H2H: "h2h/:nameString/:id1/:id2",
      Player: "player/:id",
      Article: "article/:id",
      Tournament: "tournament/:id",
      Tournaments: "tournaments",
      News: "news",
      Videos: "videos",
      Podcasts: "podcasts",
      Search: "search",
      // Fantasy:'fantasy',
      Demos: "demos/:id",
      Partners: "partners/:id",
      Rankings: "rankings",
      Ranking: "ranking/:tour/:format/:type",
      Download: "download",
      Streams: "streams",
      HowToStream: "howtostream",
      Stream: "stream/:category/:id",
      Support: "support",
      Feedback: "feedback",
      Widgets: "widgets/:id",
      WidgetBuilder: "widgetbuilder/:id",
      Widget: "widget",
      WidgetTest: "widgettest",
      FantasyRules: "fantasyrules",
      FantasyBracket: "bracket/:mode/:tournamentId/:userId",
    },
  },
};

// Gets the current screen from navigation state

const Main = ({}) => {
  const [state, setState] = useState({ loading: true });

  useEffect(() => {
    // setTimeout(()=>{
    setup();
    // },150)
  }, []);

  const setup = async () => {
    var isMobileDevice = Dimensions.get("window").width < 900;
    var isTablet = Dimensions.get("window").width < 1100;

    // console.log('bing')

    GLOBAL.displayMode = isMobileDevice
      ? "mobile"
      : isTablet
      ? "tablet"
      : "web";
    GLOBAL.style = GLOBAL.style_light;
    // GLOBAL.hideNavbar = hideNavbar;
    GLOBAL.isMobile = isMobileDevice;
    GLOBAL.refresh = refresh;
    GLOBAL.showVideo = showVideo;

    // console.log('bong')

    //Get user ip address
    const publicIp = require("public-ip");
    const ip = await publicIp.v4();
    GLOBAL.ip = ip;

    // console.log('ding')

    await setState({ ...state, isMobile: isMobileDevice, loading: false });

    // console.log('dong')
  };

  const hideNavbar = () => {
    setState({ ...state, hideNavbar: true });
  };

  const refresh = React.useCallback(async () => {
    await setState({ ...state, loading: true });
    await setState({ ...state, loading: false });
  }, []);

  const showVideo = (video) => {
    // alert('dong')
    setState((prevState) => ({ ...prevState, video }));
  };

  const clearVideo = React.useCallback(() => {
    setState({ ...state, video: null });
  }, [state]);

  const renderOverlay = () => {
    return <VideoOverlay id={state?.video} clearVideo={clearVideo} />;
  };

  // return(
  //   <View style={[styles.wrapper, {justifyContent:'center', alignItems:'center'}]}>
  //     <Text>
  //       This is the full load this is it bae
  //     </Text>
  //   </View>
  // )

  if (state.loading) return null;

  return (
    <View style={styles.wrapper}>
      <Suspense
        fallback={
          <View style={styles.suspense}>
            <ActivityIndicator color={GLOBAL.style.color} />
          </View>
        }
      >
        <NavigationContainer linking={linking}>
          {
            // loading ? null :
            <Stack.Navigator
              headerMode="none"
              transparentCard={true}
              initialRouteName="Home"
              detachPreviousScreen={true}
            >
              <Stack.Screen name="Home" component={HomeWeb} />
              <Stack.Screen name="Scores" component={HomeWeb} />
              <Stack.Screen name="Rankings" component={Rankings} />

              <Stack.Screen name="Search" component={Search} />
              <Stack.Screen name="News" component={News} />
              <Stack.Screen name="Videos" component={Videos} />
              <Stack.Screen name="Podcasts" component={Podcasts} />

              <Stack.Screen name="Article" component={Article} />

              <Stack.Screen name="Player" component={Player} />
              <Stack.Screen name="Match" component={Match} />
              {/* {<Stack.Screen name="MatchNew" component={MatchNew} />} */}
              <Stack.Screen name="Tournament" component={Tournament} />
              <Stack.Screen name="Tournaments" component={Tournaments} />
              <Stack.Screen name="Ranking" component={Ranking} />
              <Stack.Screen name="H2H" component={H2H} />

              <Stack.Screen name="Download" component={Download} />

              {<Stack.Screen name="Demos" component={Demos} />}
              {<Stack.Screen name="Partners" component={Demos} />}

              <Stack.Screen name="HowToStream" component={HowToStream} />
              <Stack.Screen name="Streams" component={Streams} />
              <Stack.Screen name="Stream" component={Stream} />

              <Stack.Screen name="Widgets" component={Widgets} />
              <Stack.Screen name="Widget" component={Widget} />
              <Stack.Screen name="WidgetBuilder" component={WidgetBuilder} />
              <Stack.Screen name="WidgetTest" component={WidgetTest} />

              <Stack.Screen name="Support" component={Support} />
              <Stack.Screen name="Feedback" component={Feedback} />
              <Stack.Screen name="FantasyRules" component={FantasyRules} />
              <Stack.Screen name="FantasyBracket" component={FantasyBracket} />
            </Stack.Navigator>
          }

          {renderOverlay()}
        </NavigationContainer>
      </Suspense>
    </View>
  );
};

export default memo(Main);

const styles = StyleSheet.create({
  wrapper: { width: "100%", flex: 1, marginTop: 0 },
  suspense: { flex: 1, justifyContent: "center", alignItems: "center" },
});
