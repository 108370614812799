/* @flow */

import React, { PureComponent } from "react";
import {
  View,
  Text,
  FlatList,
  // Pressable,
} from "react-native";

//Modules
import { Pressable } from "react-native-web-hover";
import Chip from "@mui/joy/Chip";

//Components

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import Functions from "../../helpers/functions.js";
import GLOBAL from "../../helpers/global.js";

const purple = "rgba(128, 94, 255, 1)";
const purpleLow = "rgba(128, 94, 255, 0.2)";

export default class MyComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: this.props.tabs || [],
      active_tab: this.props.active_tab,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {
    if (prevProps.tabs !== this.props.tabs) {
      this.setState({ tabs: this.props.tabs || [] });
    }
    if (prevProps.force_tab !== this.props.force_tab) {
      this.setState({ active_tab: this.props.force_tab || null });
    }
  }

  async componentWillUnmount() {}

  changeTab = (item) => {
    if (this.props.onChange) {
      this.props.onChange(item);
    }
    this.setState({ active_tab: item.id });
  };

  renderTab = ({ item, index }) => {
    var { active_tab } = this.state;
    var isActive = item.id === active_tab;
    var color = isActive ? "white" : GLOBAL.style.color;
    var backgroundColor = isActive
      ? GLOBAL.style.active_tab || purple || GLOBAL.colors.button
      : "rgba(0,0,0,0.0)";

    if (this.props.secondary && isActive) {
      if (GLOBAL.style.active_tab) {
        backgroundColor = "#4A4E53";
        color = "white";
      } else {
        backgroundColor = purpleLow;
        color = purple;
      }
    }

    // if(item.hide){return null}else{
    //   return(
    //     <Pressable key={index} onPress={()=>this.changeTab(item)} style={[{paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/2, height:48, borderBottomColor:isActive ? GLOBAL.style.color : 'transparent', borderBottomWidth:0}, MainStyles.flexCenter]}>
    //       <Text style={{color, fontFamily:GLOBAL.fonts.bold, fontSize:14, marginTop:4, textTransform:'uppercase'}}>
    //       {item?.title || item?.name}
    //       </Text>
    //       <View style={[{position:'absolute', width:'100%', height:2, opacity:isActive ? 1 : 0, bottom:0, paddingHorizontal:(GLOBAL.padding-4)}, MainStyles.flexCenter]}>
    //         <View style={{width:'100%', height:2, backgroundColor:GLOBAL.style.accent}}/>
    //       </View>
    //     </Pressable>
    //   )
    // }

    if (item.hide) {
      return null;
    } else {
      return (
        <Pressable
          key={index}
          onPress={() => this.changeTab(item)}
          style={({ hovered, focused, pressed }) => [
            {
              paddingHorizontal: 12,
              marginLeft: 4,
              borderRadius: 4,
              height: 32,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor,
              borderBottomWidth: 0,
            },
            MainStyles.flexCenter,
            hovered && !isActive && { backgroundColor: "rgba(0,0,0,0.075)" },
            // focused && styles.buttonFocused,
            // pressed && styles.buttonPressed
          ]}
          // style={[{paddingHorizontal:GLOBAL.padding, marginLeft:GLOBAL.padding/2, borderRadius:GLOBAL.borderRadius, height:30, backgroundColor, borderBottomWidth:0}, MainStyles.flexCenter]}
        >
          <Text
            style={{
              color,
              opacity: isActive ? 1 : 0.6,
              fontFamily: "Inter-Bold",
              fontSize: 12,
              marginTop: 0,
              textTransform: "uppercase",
            }}
          >
            {item?.title || item?.name}
          </Text>
          {item.count > 0 && (
            <View
              style={{
                paddingHorizontal: 4,
                marginLeft: 6,
                paddingVertical: 0,
                backgroundColor:
                  isActive && !GLOBAL.style.active_tab ? purple : "#EC1181",
                // backgroundColor: "#EC1181",
                borderRadius: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "white",
                  fontFamily: "Inter-Bold",
                }}
              >
                {item.count}
              </Text>
            </View>
          )}
        </Pressable>
      );
    }
  };

  render = () => {
    var { tabs } = this.state;
    try {
      if (tabs.length > 1) {
        return (
          <FlatList
            style={{
              width: "100%",
              borderBottomColor: GLOBAL.style.borderColor,
              backgroundColor: "transparent",
              borderBottomWidth: 0,
              marginVertical: 10,
            }}
            contentContainerStyle={{
              paddingHorizontal: 0,
              paddingRight: 16,
              paddingLeft: 8,
            }}
            data={tabs}
            renderItem={this.renderTab}
            horizontal
            showsHorizontalScrollIndicator={false}
          />
        );
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };
}
