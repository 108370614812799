/* @flow */

import React, { memo, useEffect, useMemo, useState } from "react";
import { View, Text, Pressable, Dimensions } from "react-native";

//Modules

//Components
// import Image from '../general/image.js'
import Icon from "../general/icon.js";

//Helpers
import MainStyles from "../../helpers/mainstyles.js";
import GLOBAL from "../../helpers/global.js";
import { useNavigation, useRoute } from "@react-navigation/native";

const purple = "#805EFF";

const items = [
  {
    title: "Latest",
    link: "/news",
    page: "News",
    pages: ["News", "Videos", "Podcasts"],
    icon: "news",
  },
  {
    title: "Scores",
    link: "/",
    page: "Home",
    pages: ["Home", "Match", "Scores"],
    icon: "scoreboard",
  },
  {
    title: "Tournaments",
    link: "/tournaments",
    page: "Tournaments",
    pages: ["Tournaments", "Tournament"],
    icon: "court",
  },
  {
    title: "Rankings",
    link: "/rankings",
    page: "Rankings",
    pages: ["Rankings", "Ranking", "Player"],
    icon: "podium",
  },
];

const NavBar = ({ showBackground }) => {
  const [state, setState] = useState({
    loading: true,
    darkMode: GLOBAL.darkMode,
    showBackground: showBackground,
    searchTerm: "",
    showSearch: false,
    loadingSearch: true,
    tournaments_all: [],
    players_all: [],
    showMenu: false,
  });

  const route = useRoute()
  const page = useMemo(()=>{
    return route.name
  },[route.name])

  const navigation = useNavigation();

  useEffect(() => {
    GLOBAL.hideNav = hideNav;
  }, []);

  const hideNav = () => {
    setState({ ...state, hideNav: true });
  };

  const goHome = () => {
    navigation.navigate("Home");
  };

  const showMenu = () => {
    setState({ ...state, showMenu: !state.showMenu });
  };

  const showSearch = () => {
    setState({ ...state, showSearch: !state.showSearch });
  };

  const openPage = (page) => {
    GLOBAL.page = page;
    navigation.navigate(page);
  };

  const renderTab = (item, index) => {
    const active = item.pages.includes(page);
    const tintColor = active ? purple : GLOBAL.style.color;
    const fallbackOpacity = 0.5;
    return (
      <Pressable
        onPress={() => openPage(item.page)}
        style={[
          {
            flex: 1,
            opacity: active ? 1 : fallbackOpacity,
            marginTop: -2,
            marginBottom: 8,
          },
          MainStyles.flexCenter,
        ]}
      >
        <Icon icon={item.icon} filled={active} color={tintColor} />
        <Text
          style={{
            color: tintColor,
            fontSize: 10,
            fontFamily: "Inter-Medium",
            marginTop: 0,
          }}
        >
          {item.title}
        </Text>
      </Pressable>
    );
  };

  if(GLOBAL.displayMode === 'mobile' && !state.hideNav){
    return(
      <View style={[{backgroundColor:GLOBAL.style.cardColor, paddingBottom:4, paddingTop:8, width:'100%', borderTopColor:GLOBAL.style.borderColor, borderTopWidth:1}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {items.map((item, index) => renderTab(item, index))}
      </View>
    )
  }else{return null}
};

export default memo(NavBar);
