import GLOBAL from './global.js'

import {
  Linking,
} from 'react-native';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')
const cjson = require('compressed-json')

const firebaseConfig = {
  apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
  authDomain: "tennis-lwts.firebaseapp.com",
  projectId: "tennis-lwts",
  storageBucket: "tennis-lwts.appspot.com",
  messagingSenderId: "414782898270",
  appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
  // measurementId: "G-D3SD6S9JJW"
  measurementId:"G-WVPSD2WN29",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// //Amplitude
// import RNAmplitude from 'react-native-amplitude-analytics';
// const amplitude = new RNAmplitude('d559e29279c94b288e185ab7b344a61e');

const Functions = {
  // //Amplitude
  // amplitudeEvent: async (eventName, props) => {
  //   if(props){
  //     amplitude.logEvent(eventName, props);
  //   }else{
  //     amplitude.logEvent(eventName);
  //   }
  // },
  //
  // amplitudeSetUserProperty: async (props) => {
  //   amplitude.setUserProperties(props)
  // },

  googleAnalytics: async (event_name, params) => {
    logEvent(analytics, event_name, params || {})
  },

  tnnsAPI: async (params) => {
    var data = null

    if(!params){
      params = {}
    }

    // var url = "https://us-central1-tennis-lwts.cloudfunctions.net/tnns_web_api"
    // var url_testing = "https://us-central1-tennis-lwts.cloudfunctions.net/tnns_web_api_testing"

    // var url = "https://tnnz.io/api_web"
    let url = 'https://gen2-web-api-ysvbugl7mq-uc.a.run.app'
    // url = 'https://gen2-web-api-testing-ysvbugl7mq-uc.a.run.app'
    // url = 'http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_web_api'
    var url_testing = "https://tnnz.io/api_web_testing"

    if(params.url){
      url = params.url
    }

    // url = url_testing

    if(url === url_testing && !GLOBAL.alerted_testingMode){
      GLOBAL.alerted_testingMode = true
      alert('Using testing API')
    }

    // console.log(url)

    //Params
    var tz = await moment.tz.guess(true)
    // const token = await firebase.auth().currentUser.getIdToken(true)

    // try{
    //   token = await firebase.auth().currentUser.getIdToken(true);
    // }catch(e){
    //   console.log('Error getting token', e)
    // }

    // params.authorization = token
    params.userId = GLOBAL.userId
    params.timezone = tz
    params.language = 'en'
    params.theme = GLOBAL.theme
    params.platform = 'web'
    params.version = '100'
    // params.versionNumber = GLOBAL.versionNumber
    params.spoilerMode = GLOBAL.spoilerMode
    params.subscribed = {}
    params.favorites = {}
    params.theme_settings = {}

    if(['match','match_info', 'form', 'player'].includes(params.mode)){
      url = 'https://gen2-web-api-testing-ysvbugl7mq-uc.a.run.app'
      // url = 'http://127.0.0.1:5001/tennis-lwts/us-central1/gen2_web_api'
    }

    // params.versionNumber = 450

    // params.country_force = "United States of America"

    //Headers
    // var headers = {
    //   'Authorization':`Bearer ${token}`,
    // }

    await axios
    .get(url, {params})
    // .get(url, {params}, {headers})
    .then(async (response) => {
      // console.log(response)
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log(params.mode, error)
      if(params.mode !== 'web_error'){
        await Functions.tnnsAPI({mode:'web_error', error})
      }
    });

    // console.log(data)

    if(data){
      if(data.K){
        try{
          data = cjson.decompress(data)
        }catch(e){
          console.log(e)
        }
      }
    }

    return data
  },

  //API
  firestoreAPI: async (url_raw) => {
    var data = null

    var language = GLOBAL.language || 'en'

    var tz = await moment.tz.guess(true)
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    await axios
    .get(url)
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  },

  openURL: async (url, newTab) => {
    // window.open(url, "_self")
    window.open(url)
  },

  openMatch: async(data, tournament, external) => {
    tournament = (tournament || data.tournament || data.season_name || '').split(" ").join("-")
    var players = ((data.competitors[0].name+"-vs-"+data.competitors[1].name+"-live").split(" ").join("-")).split("/").join("")

    let id = data.id
    if(id.includes('gs:')) id = `g-${id.split(":")[2]}`
    if(id.includes('sr:')) id = id.split(":")[2]

    if(!GLOBAL.navigation){
      Linking.openURL(`https://tnnslive.com/match/${id}`)
    }else{
      // GLOBAL.navigation.push('Match', {id:data.docId})
      GLOBAL.navigation.push('Match', {id})
    }
  },

  openYouTube: async (url) => {
    Functions.openURL(url)
  },

  openVideo: async (data) => {
    Functions.openURL(data.url)
  },

  openPlayer: async(data) => {
    if(data.id.includes("sr:")){
      data.id = "sr"+data.id.split("competitor:")[1]
    }
    var name = (data.name.split(", ").reverse().join(" ")).split(" ").join("-")
    GLOBAL.navigation.push('Player', {id:data.docId || data.id, name:name||''})
  },

  openTournament: async(data) => {
    var name = data.name ? data.name.split(" ").join("-") : ''
    var city = data.city ? data.city.split(" ").join("-") : ''
    var year = data.season || 2021

    var tags_array = data.tags || []
    var tags = []
    await Promise.all(tags_array.map(async (tag, index) => {
      tags.push(tag.title)
    }))

    var type = (tags.join("_")).split(" ").join("_")
    name = name.split(" ").join("_")

    if(data.subtitles){
      if(data.subtitles[1]){
        city = data.subtitles[1].split(", ")[0]
      }
    }

    var category = 'tennis'

    GLOBAL.navigation.push('Tournament', {id:data.docId || data.id, name, city, year, category})
  }

}

export default Functions;
